<template>
  <v-row>
    <v-cotainer align="center" justify="center" class="gdprContainer mt-10">
      <v-row justify="center" class="mb-10 mt-10">
        <router-link to="/">Go back</router-link>
      </v-row>

      <h1>GDPR & Privacy Policy</h1>
      <div>
        Buttery photography operates as a freelance entity. This policy will
        explain how our organisation uses the personal data we collect from you
        when you interact with our website
      </div>

      <div class="text-h4 mt-10">What data do we collect?</div>
      <div>We collect the following data:</div>
      <div>
        -Personal identification information (Name, email, phone and reason for
        contact)
      </div>
      <div>
        -Event information, this is only requested during our event booking
        process
      </div>

      <div class="text-h4 mt-10">How do we collect your data?</div>
      <div>
        -We collect data that you have directly provided through our contact
        form
      </div>
      <div>
        -If you choose to book our services, we are required to store data about
        your booking e.g. booking date / venue / invoices / contracts
      </div>

      <div class="text-h4 mt-10">How Will we use your data?</div>
      <div>
        -Process your enquiry e.g. through contacting you via email / phone
      </div>
      <div>-Images may be used on our website and social media channels</div>

      <div class="text-h4 mt-10">How do we store your data?</div>
      <div>
        -Data submitted via our contact form is emailed directly from the
        website to our email account which is stored on the email providers
        server
      </div>
      <div>
        -Our company will keep your email data for 6 months after inital
        contact. If you choose to book our services, we will hold onto your data
        until 6 months after your event date
      </div>
      <div>
        -Data gathered from our booking process will be held for 6 months after
        your event date
      </div>
      <div>
        -Images gather from your event will be held for up to 3 years after your
        event
      </div>
      <div>
        -Data erasure of emails, contracts, invoices, contact details, images
        and any other data will occur after the above time frames
      </div>

      <div class="text-h4 mt-10">What are your data protection rights?</div>
      <div>
        We would like to make sure you are aware of your data protection rights.
        Users are entitled to the following:
      </div>
      <div>
        -The right to access - You have the right to request copies of any
        personal data we hold on yourself. Identification proof will be
        required.
      </div>
      <div>
        -The right to rectification - You have the right to request that we
        correct any information we store on yourself that you believe is
        inaccurate. You also have the right to request we complete information
        that we store on yourself that you believe is incomplete
      </div>
      <div>
        -The right to erasure - You have the right to request that we erase your
        personal data, under certain conditions
      </div>
      <div>
        -The right to restrict processing - You have the right to request that
        we restrict the processing of your personal data, under certain
        conditions
      </div>
      <div>
        -The right to object to processing - You have the right to object to our
        processing of your data, under certain circumstances
      </div>
      <div>
        -The right to data portability - You have the right to request that we
        transfer the data we have collected on yourself, directly to you or
        another company, under certain circumstances. If you make this request,
        we have one month to respond to you
      </div>

      <div class="mt-10">
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us on our email:
        callumbuttery@icloud.com
      </div>

    </v-cotainer>
  </v-row>
</template>

<script>
export default {
  name: "Gdpr",
};
</script>

<style>
.gdprContainer {
  width: 45%;
  height: 100%;
  margin: auto;
  text-align: left;
  padding-bottom: 100px;
}
</style>
